.buttonDisplay {
    margin-left: 20px;
    margin-bottom: 10px;
}

.sopRadioButton {
    margin-top: 20px;
}

.mainBox {
    margin: 15px;
}

.sopTable {
    border: none !important;
    box-shadow: none !important;
}

.katCellLabel {
    border-top: none !important;
}

.textArea {
    display: flex !important;
    margin-left: 25px !important;
    width: 60%;
    --resize: horizontal;
    textarea{
        min-height: 20px !important;
    }
}

.sopLink{
    margin-left: 5px;
}

.sopDescription{
    color: #343533;
}

.sopOption{
    display: flex;
    border: none;
    padding: 0;
}

.starIcon {
  color: #007587;
  margin-right: 5px;
}