.styledDiv {
    margin-bottom: 14px;
    // display: flex;
    // flex-direction: row;
}

.radioGroup {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;
}

.caseStatusOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
}

.dcpFormView {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}

.dcpLink {
    background-color: #e3eced;
    align-items: center;
}

.dcpCheckbox {
    margin-left: 10px;
    align-items: center;
}