
.overRideKatBox {
    margin-bottom: 10px;
    --kat-card-background: transparent;
    --border-color: #008296;
    --border-style: solid;
    --border-width: 1px;
}

.transitionButtonGroup {
  margin-top: 26px;
}

.transitionButton {
  margin-right: 10px;
}

.recipientGroup {
  margin-top: 18px;
}

.infoRow {
  display: flex;
}

.prependText {
  width:125px;
  text-align: left;
  padding: 0 !important;
  font-size: 13px !important;
  border-radius: 0 !important;
  display: flex;
}

.emailContentCard {
  margin-top: 18px !important;
  overflow: auto;
  max-height: 500px;
  background-color: #ffffff;
  border: 1px solid #929393 !important;
  border-radius: 3px;
  padding: 10px;
}

.attachmentReview {
  padding-bottom: 14px;
  border-bottom: 1px solid #dee2e6 !important;
}

.componentHeader {
  margin-top: 14px !important;
  margin-bottom: 4px !important;
}