.styledKatTextarea {
  display: flex !important;
  --border-color: #929393;
  --padding: 10px;
  --border-radius: 3px;
  width: 100%;
  height: 100%;
  --resize: vertical;
  --height: 100px;
  --background: white;
  box-shadow: none;
  pointer-events: all;
  margin-bottom: 10px;
}

.styledInsertButton {
  margin-top: 10px;
  padding: 2px;
  display: flex !important;
  width: fit-content;
}

.styledPopover {
  --arrow-height: 0px;
  --arrow-width: 0px;
  --arrow-outlay: 0px;
  color: black;
  pointer-events: all;
}

.overlay {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
}

.styledIconContainer {
  color: white;
  background: #008296;
  border-radius: 7px;
  text-align: center;
  padding: 3px;
  margin-right: 5px;
}

.alignRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

