.mainBox {
    overflow: auto;
    flex-wrap: wrap;
}

.taskBox {
    margin: 20px;
}

.activityBox {
    margin: 20px;
}

.accordianBox {
    margin-bottom: 10px;
}

.taskLabel {
    margin-bottom: 20px;
}

.renderImage {
    border: none;
}

.sopTitle {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}