.dropDownSection {
  padding-left: 0 !important;
  padding-right: 20px !important;
  margin-bottom: 14px !important;
}

.issueDropDownSection {
  padding-right: 0;
}

.dropdownHeader {
  margin-bottom: 4px;
}

.styledDiv {
  width: 320px;
  margin-right: 10px;
}