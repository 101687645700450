.nonEligibleLabel {
  color: #ff2323;
}

.eligibleLabel {
  color: #438714;
}

.finalWordVerifierContainer {
  margin-top: 14px !important;
  margin-bottom: 4px !important;
  display: flex;
  align-items: center;
}

.checkFinalWordButton {
  --background-hover: none;
  --border-color-hover: none;
  --border-radius: 0;
  --border-style: none;
  --box-shadow: none;
  --color: #008296;
  --padding: 0;
}