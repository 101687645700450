.reasonCategoryHeader {
  margin-bottom: 4px;
}

.reasonCategoryContainer {
  margin-right: 10px !important;
  width: 200px;
}

.reasonCodeContainer {
  width: 200px;
}