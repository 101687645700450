.bigButtonBorder {
  border: 1px solid #7FC2BB;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
  padding: 0.5rem;
  box-shadow: 0 0 2px #008296;
  border-radius: 2px;

  &:hover {
    background-color:#7FC2BB;
  }
}

.hoveredDiv {
  color:#002F36;
  font-weight: bold;
}

.noHoveredDiv {
  color:#008296;
  font-weight: bold;
}

.hoveredKatIcon {
  color:#002F36;
  margin-top: 6px;
  padding-left: 5px;
}

.noHoveredKatIcon {
  color: #008296;
  margin-top: 6px;
  padding-left: 5px;
}