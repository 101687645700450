.transferStaticNote {
    margin-top: 18px;
}

.lockCaseToOwner {
    margin-top: 26px;
}

.transferButtons {
    padding-top: 18px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
