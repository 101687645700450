.statusIndicatorDiv {
  margin-top: 4px;
}

.styledKatStatusIndicator {
  --font-size: 13px;
}

.headerKatLabel {
  display: flex;
  margin-top: 14px !important;
  margin-bottom: 4px !important;
}

.styledKatTextarea {
  margin-top: 5px;
  display: flex !important;
  --border-color: #929393;
  --padding: 10px;
  --border-radius: 3px;
  width: 100%;
  height: 100%;
  --resize: vertical;
  --height: 200px;
  --background: white;
  box-shadow: none;
}