
.replyCaseWidget {
    --page-section-padding: 20px;
    --kat-card-border: 1px solid #aab;
    --kat-card-padding: 0.625rem var(--page-section-padding);
    background-color: #fff;
    border: var(--kat-card-border);
}


