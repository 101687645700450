.caseSellerInfo {
  padding: 20px 20px 30px 36px;
  width: 420px;
  background-color: #FAFAFA;
}

.caseInfoTable {
  border: none !important;
  box-shadow: none !important;
}

.normalStatus {
  color: #538000;
  font-weight: 700;
  font-size: 14px;
}

.emailQueue {
  word-break: break-all;
}

.reasonCode {
  text-align: right;
}

.deactivatedStatus {
  color: #CC0C39;
  font-weight: 700;
  font-size: 14px;
}

.blockedStatus {
  color: #f9cd0b;
}

.expandableListTrigger {
  background-color: transparent;
  border: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #008296;
  max-width: 100%;
  line-height: 1;
  cursor: pointer;
  padding: 0 0 0 5px;
}

.brandsPopoverContent {
  height: 100px;
  width: 500px;
  overflow: scroll;
  scrollbar-width: none;
}

.brandsListRow {
  height: fit-content !important;
}

.status {
  padding-left: 5px;
}

.expandLink {
  font-size: 13px !important;
  cursor: pointer;
  padding-top: 10px;
}

.expandLinkText {
  color: #008296;
}

.spinnerBox {
  position: relative;
  width: 32px;
  height: 32px;
  display: inline-block;
}

.peeknowButton {
  padding-bottom: 10px;
}