.peekNowButton {
  /* Auto layout */
  display: flex !important;
  flex-direction: row;
  align-items: center;

  /* Button Shadow */
  box-shadow: 0px 1px 2px #b5b5b5;
  border-radius: 1px;

  /* Button Label */
  margin-bottom: 20px;
  margin-top: 5px;
  margin-right: 15px;
  
  width: fit-content !important;

  /* Body/Regular */
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;

  /* 1. Primary/Nordic #002F36 */
  color: #002f36;
}

.popoverContent {
  margin-bottom: 1em;
}

.spinnerBox {
  position: relative;
  width: 32px;
  height: 32px;
  display: inline-block;
}