.inputGroupTagsContainer {
  flex: fit-content;

  input{
    flex: fit-content;
    border: none;
    padding: 10px;
    background-color: unset;
  }

  input:focus{
    outline: none;
  }

  i{
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: #764abc;
  }
}

.tagGroup {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 80%;
  color: black;
}

.tag {
  margin: 7px 14px 7px 0;
}