.actionPlanWidget {
    margin: 5px;
}

.mainBox {
    margin: 20px;
}

.errorBanner{
    margin: 20px;
}