.multiSelectInput {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    margin-top: 4px;
    margin-bottom: 6px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.manualAttributeBox {
    margin-top: 26px;
    margin-bottom: 26px;
    display: flexbox;
    flex-direction: row;
    gap: 10px;
}

.manualAlert {
    gap: 10px;
    margin-bottom: 16px;
}

.textarea {
    width: 100%;

    textarea {
        resize: vertical;
    }
}

.filterInput {
    width: 100%;
}

.fixDropdownHeight {
    height: auto;
}

.transferTypesDropDown {
    width: 120px;
}