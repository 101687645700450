.buttonDisplay {
    margin-left: 15px;
    margin-bottom: 10px;
    width: 160px;
}

.mainBox {
    margin: 20px;
}

.headerProgressBar {
    margin-top: 10px;
}

.displayHeader {
    margin: 15px;
}

.labelBox {
    margin: 20px;
}

.progressButtons {
    margin-left: 20px;
}

.cancelReason {
    display: flex;
    flex-direction: column;
}

.cancelText {
    margin-top: 10px;
    margin-bottom: 10px;
}

.stylePopoverContent{
    flex-wrap: wrap;
}