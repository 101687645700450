.tardisTransferBox {
  margin-top: 26px;
  margin-bottom: 26px;
  flex-direction: row;
  gap: 10px;
}

.manualAlert {
  gap: 10px;
  margin-bottom: 16px;
}
