.attachmentModuleDiv {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 14px !important;
}

.componentHeader {
  margin-top: 14px !important;
  margin-bottom: 4px !important;
}

.attachmentFileUpload {
  margin-bottom: 4px;
}