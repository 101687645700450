.failureSpan {
  color: red;
  font-weight: 500;
  margin-right: 7px;
}

.successSpan {
  color: green;
  font-weight: 500;
  margin-right: 7px;
}