.katTableRow {
  display: flex !important;
  flex-direction: row;
  height: 30px;
}

.katCellLabel {
  flex-grow: 1;
  flex-shrink: 0;
  border-top: none !important;
}

.katCellValue {
  display: flex !important;
  flex-grow: 2;
  justify-content: end !important;
  border-top: none !important;
  padding-left: 0px !important;
}