.issueSummary {
  width: 65%;
  padding: 20px 40px;
}

.sellerIssue {
  padding-top: 20px;
}

.issueSummaryHeadings {
  font-weight: bold;
}

.LLMTextLink {
  display: inline-flex;
}

.issue {
  align-items: flex-end !important;
}


.sourceButton {
  background-color: transparent;
  border: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #008296;
  max-width: 100%;
  line-height: 1;
  cursor: pointer;
  padding: 0 0 0 5px;
}

.sourceButton:hover {
  text-decoration: underline;
}

.issueSummaryAlert {
  padding-top: 10px;
  padding-bottom: 15px;
}

.predictedText {
  padding-top: 10px;
  padding-bottom: 15px;
}

.actionsList {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.action {
  padding-top: 5px;
}

.identifierGrid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 10px;
  align-items: start;
}

.valuesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.spinnerBox {
  position: relative;
  width: 32px;
  height: 32px;
  display: inline-block;
}

.copyAllIcon {
  color: #667575;
}
