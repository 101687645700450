.feedbackKatButton {
  --background-hover: none;
  --border-color-hover: none;
  --border-radius: 0;
  --border-style: none;
  --box-shadow: 0;
  --padding: 0;
  --color: #008296;
  margin-top: 4px;
}

.atlasLabelDiv {
  margin-right: 10px;
}

.containerDiv {
  margin-bottom: 14px;
}

.dropDownSection {
  padding-left: 0 !important;
  padding-right: 20px !important;
  margin-bottom: 14px !important;
}

.issueDropDownSection {
  padding-right: 0;
}

.styledDiv {
  margin-bottom: 4px;
}