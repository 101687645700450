.transferBox{
    margin-top: 26px;
    margin-bottom: 26px;
}

.languageCheckBox{
    margin-left: 30px;
}

.languageDisplay{
    margin-top: 20px;
    margin-left: 50px;
    width: 340px;
    padding: 14px;
}

.languageNote{
    margin-left: 10px;
}

.languagesDropDown{
    margin-top: 10px;
    margin-left: 10px;
}

.errorAlert{
    margin-left: 10px;
    color: red;
    font-size: 12px;
}

.manualAlert {
    gap: 10px;
    margin-bottom: 16px;
}
