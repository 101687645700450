.copyIcon {
  color: #7f7f7f;
  cursor: pointer;
  transition: background-color 2s, color 2s;
  transition-delay: 0s;
  &.success {
    background-color: var(--kat-alert-bkg-success, #edf2e5);
    color: var(--kat-alert-icon-color-success,#538000);
    transition-delay: -2s;
  }
}

.copyPopOver {
  --background: #002F36;
  --color: #FFFFFF;
}