.bigButtonsContainer {
  margin-top: 26px;
  display: block;
}

.bigButtonLink {
  margin-bottom: 10px;
  width: 100%;

  &:last-child {
    margin-bottom: 25px;
  }
}

.ptrModalContent {
  max-width: 800px !important;
}

.modalContainer {
  display: flex;
  min-height: 500px;
}