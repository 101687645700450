.spanLabel {
  width: auto;
  height: 35px;
  padding: .5rem 1.1rem !important;
  min-width: 125px !important;
  max-width: 125px !important;
  text-align: left;
  font-size: 13px !important;
  border-style: none !important;
  display: flex;
  background-color: #f6f6f6;
}

.composerKatInput {
  border-radius: 0 !important;
  border: 0 none !important;
  width: 100% !important;

  input {
    box-shadow: none !important;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.checkKatIcon {
  border: none;
  background-color: unset;
  color: #28A745;
  font-size: larger !important;
}

.crossKatIcon {
  color: #DC3444;
  font-size: large !important;
  border: none;
  background-color: unset;
}

.styledInputGroup {
  border-bottom: 1px solid #138296 !important;
}