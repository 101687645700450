
.textAreaContainer {
    position: relative;
    height: 200px;
    border-color: #aab7b8;
    border-style: solid;
    border-width: 1px;
    border-right: none;
    border-left: none;
}

.styledKatTextarea {
    display: flex !important;
    width: 100%;
    --resize: none;
    --height: 180px;
    --background-color: transparent;
    --border-width: 0px;
    --box-shadow: none;
    --overflow: hidden;
    --focus-ring-width: 0px;
}

.dropdownBox {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-end;
}

.topFakePlaceholder {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-top: 5px;
    color:#667575;
    z-index: 1;
    pointer-events: none;
}

.bottomFakePlaceholder {
    position: absolute;
    top: 175px;
    left: 0;
    padding-left: 10px;
    padding-top: 5px;
    color:#667575;
}

.buttonContainer {
    padding: 10px;
}