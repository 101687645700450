.noBorderKatCard {
  border: 0;
}

.replyHeader {
  font-weight: normal;
}

.styledHr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-top: 1px solid #138296;
}

.outboundMessageContainer {
  display: flex;
  flex-direction: column;
}

.outboundMessageHeader {
  height: 35px;
  border-top: 1px solid #138296 !important;
}

.outboundMessageHeaderText {
  display: flex;
  float: left;
  margin-top: 14px !important;
  margin-bottom: 4px !important;
}

.outboundMessageWarning {
  padding-right: 77%;
}

.mainEmailBodyComponent {
  margin-bottom: 5px;
}

.emailKatButton {
  margin-right: 10px;
  margin-top: 14px;
}

.transitionButtonGroup {
  margin-top: 26px;
}

.transitionButton {
  margin-right: 10px;
}

.overrideKatBox {
  margin-bottom: 10px;
  --kat-card-background: transparent;
  --border-color: #008296;
  --border-style: solid;
  --border-width: 1px;
}

.atlasLabelDropdownMargin {
  margin-bottom: 18px;
}

